import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import Image from "@atoms/Image";
import React, { useEffect, useState } from 'react';
import Button from "@atoms/Button";
import useWindowDimensions from "@hooks/useWindowDimensions";
import VisibilitySensor from "react-visibility-sensor";

const StartInvesting = ({ onButtonClick }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [gotoNextStep, setGotoNextStep] = useState(false);
    const { width } = useWindowDimensions();
    const onStepClick = (index) => {
        if (currentStep == index) return;
        setCurrentStep(index);
    }
    var timeoutId;

    const onChangeVisibility = (isVisible) => {
        setGotoNextStep(isVisible);
    }

    useEffect(() => {
        if (gotoNextStep) {
            timeoutId = setTimeout(() => setCurrentStep(i => (i + 1) % StartInvestingSteps.length), 5000);
        }

        return () => { if (timeoutId) clearTimeout(timeoutId); }
    }, [currentStep, gotoNextStep]);

    const StartInvestingSteps = [
        {
            title: texts?.InvestStep1,
            description: texts?.DocumentsHandy,
            descriptionBottomMargin: '-bottom-12 xl:-bottom-10',
            image: '/images/homepage/desktop/documents.webp',
            mobileImage: '/images/homepage/mobile/documents.webp',
            tabletImage: '/images/homepage/mobile/documents.webp',
            imageClassName: 'w-[221px] xl:w-[568px] xl:mb-10'
        },
        {
            title: texts?.InvestStep2,
            description: texts?.DetailedInformation,
            descriptionBottomMargin: '-bottom-20',
            image: '/images/homepage/desktop/opportunities-list.webp',
            mobileImage: '/images/homepage/mobile/opportunities-list.webp',
            tabletImage: '/images/homepage/mobile/opportunities-list.webp',
            imageClassName: 'w-full',
        },
        {
            title: texts?.InvestStep3,
            description: texts?.BankTransfer,
            descriptionBottomMargin: '-bottom-[72px] xl:-bottom-16',
            image: '/images/homepage/desktop/transfer-fund.webp',
            mobileImage: '/images/homepage/mobile/transfer-fund.webp',
            tabletImage: '/images/homepage/mobile/transfer-fund.webp',
            imageClassName: 'w-[300px] lg:w-[443px]',
        },
    ];

    return (
        <VisibilitySensor onChange={onChangeVisibility} partialVisibility>
            <div className="px-4 py-12 max-width-wrapper md:py-16 md:px-6 xl:py-18 xl:px-16">
                <Text
                    className="text-center text-primary-400 p4-medium md:p4-medium xl:p2-medium md:text-start"
                    content={texts?.WealthCreation}
                />
                <Text
                    className="mt-2 text-center text-gray-800 h4-semibold md:h3-semibold xl:h1-semibold md:text-start"
                    content={texts?.StartInvestingIn3Steps}
                    htmlTag="h2"
                />
                <div className="flex flex-col w-full mt-6 md:mt-10 md:flex-row">
                    <div className="relative md:basis-[45%] xl:basis-[40%] z-10 flex flex-row md:flex-col justify-center gap-y-6 md:my-5">
                        <div
                            className={`absolute block md:hidden xl:block bg-basicWhite w-1/3 h-full xl:w-[110%] xl:h-[115px] top-0 left-0 rounded-t-lg xl:rounded-xl step-box step-box-${currentStep}`}
                        />
                        {StartInvestingSteps?.map((step, index) => (
                            <div
                                key={index}
                                onClick={() => onStepClick(index)}
                                gtmtag={
                                    texts?.InvestmentStepsGtmTag + (index + 1)
                                }
                                className={`relative z-1 cursor-pointer w-full md:w-[110%] md:pr-8 ${
                                    index == currentStep
                                        ? 'bg-basicWhite rounded-t-lg md:rounded-xl active-step'
                                        : 'bg-transparent'
                                    }`}
                            >
                                <div
                                    className={`py-2 md:p-4 flex gap-x-4 justify-center md:justify-start`}
                                >
                                    <div className="hidden px-1 py-2 max-h-min shrink-0 md:block">
                                        <Image
                                            src="/images/homepage/arrow-right-gray.svg"
                                            className="w-10 h-6"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-y-3">
                                        <Text
                                            content={`0${index + 1}`}
                                            className={`step-text ${
                                                index == currentStep
                                                    ? 'text-secondary-500'
                                                    : 'text-gray-400 md:text-gray-500'
                                                } h2-regular`}
                                        />
                                        <Text
                                            content={step.title}
                                            className={`hidden md:block text-basicBlack step-text ${
                                                index == currentStep
                                                    ? 'p4-medium xl:p3-medium'
                                                    : 'p4-regular xl:p3-regular'
                                                }`}
                                            htmlTag="h3"
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className={`md:basis-[55%] xl:basis-[60%] relative bg-primary-50 rounded-b-xl md:rounded-xl w-full overflow-hidden`}
                    >
                        <div
                            className={`w-full h-full flex md:items-center flex-nowrap step-details step-details-${currentStep}`}
                        >
                            {StartInvestingSteps?.map((step, index) => (
                                <React.Fragment key={index}>
                                    <div
                                        className={`hidden md:flex h-full items-center justify-center flex-none w-full`}
                                        gtmtag={
                                            texts?.InvestmentStepsGtmTag +
                                            (index + 1)
                                        }
                                    >
                                        <div className="relative flex justify-center w-full">
                                            <Image
                                                src={
                                                    width < 1280
                                                        ? width < 768
                                                            ? step.mobileImage
                                                            : step.tabletImage
                                                        : step.image
                                                }
                                                className={step.imageClassName}
                                            />
                                            <div
                                                className={`w-full absolute ${step.descriptionBottomMargin} flex justify-center`}
                                            >
                                                <Text
                                                    content={step.description}
                                                    className={` w-[335px] text-center text-gray-900 p4-regular`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex flex-col items-center justify-between min-w-full px-3 py-8 md:hidden gap-y-6">
                                        <Text
                                            content={step.title}
                                            className="w-3/4 text-center text-gray-900 p4-semibold"
                                        />
                                        <Image
                                            src={step.mobileImage}
                                            className={`${step.imageClassName}`}
                                        />
                                        <Text
                                            content={step.description}
                                            className="w-3/4 text-center text-gray-900 p4-regular"
                                        />
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                <Button
                    design="standard"
                    type="primary"
                    onClick={onButtonClick}
                    paddingClass="py-3 px-6"
                    cursorClass="cursor-pointer"
                    borderRadiusClass="rounded-full"
                    className="mx-auto bg-primary-500 md:ml-20 mt-7"
                    gtmTag="gtm-click-start-investing-btn"
                >
                    <Text
                        content={texts?.StartInvesting}
                        className="w-[153px] text-basicWhite p4-semibold"
                    />
                </Button>
            </div>
        </VisibilitySensor>
    );
};

export default StartInvesting;